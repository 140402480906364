<style> .sidenav-overlay { display:none !important;}</style>
<template lang="">
    <b-card no-body>
        <b-card-body>
          <b-row class="d-flex align-items-center mb-1">
                        <b-col md="5">
                            <b-form-group label="From">
                                <b-form-datepicker
                                    id="example-datepicker1"
                                    v-model="fromDate"
                                    class="mb-1"
                                />
                            </b-form-group>
                        </b-col> 
                        <b-col md="5">
                            <b-form-group label="To">
                                <b-form-datepicker
                                    id="example-datepicker2"
                                    v-model="toDate"
                                    class="mb-1"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="2" sm="4" xs="5">
                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                @click="search"
                            >
                                Search
                            </b-button>
                        </b-col>
                        
                    </b-row>
    
            <b-card text-variant="center" class="card card-congratulations">
              <h2 class="mb-1 mt-10 text-white">Report</h2>
                <b-card-text class="m-auto w-75">
                    Payment Date: <strong>{{ paymentDate }}</strong>
                </b-card-text>
                <b-card-text class="m-auto w-75"
                    >From <strong>{{ fromDate }} </strong> To
                    <strong> {{ toDate }}</strong>
                </b-card-text>
            </b-card>
            <b-tabs align="right">
                <b-tab active title="Payroll Report">
                  <b-overlay :show="showUpdate" rounded="sm" style="width:100%;">

                    <div class="miles_wise" v-if="driverDetails.pay_type == 1">
                      <b-table
                        striped
                        hover
                        responsive
                        class="position-relative"
                        :items="totalDataTable"
                        :fields="totalFields"
                    />

                    <b-table
                        striped
                        hover
                        responsive
                        class="position-relative"
                        :items="detailDataTable"
                        :fields="detailFields"
                    />

                    </div>

                    <div class="hour_based" v-if="driverDetails.pay_type == 2">
                      <b-table
                        striped
                        hover
                        responsive
                        class="position-relative"
                        :items="totalDataHourlyTable"
                        :fields="totalHourlyFields"
                    />

                    <b-table
                        striped
                        hover
                        responsive
                        class="position-relative"
                        :items="detailDataHourlyTable"
                        :fields="detailHourlyFields"
                    />

                    </div>

                    <div class="day_based" v-if="driverDetails.pay_type == 3">
                      <b-table
                        striped
                        hover
                        responsive
                        class="position-relative"
                        :items="totalDataDayTable"
                        :fields="totalDayFields"
                    />

                    <b-table
                        striped
                        hover
                        responsive
                        class="position-relative"
                        :items="detailDataDayTable"
                        :fields="detailDayFields"
                    />

                    </div>
                   
                    </b-overlay>
                </b-tab>
                
            </b-tabs>
            
        </b-card-body>
        <b-button v-b-modal.modal-tall v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">
            Dispute Earning Reports
                </b-button>


     <!-- select 2 demo -->
     <b-modal size="lg" id="modal-tall" ref="modal-tall" :hide-footer="true" title="">
            <validation-observer ref="simpleRules" >
        <b-form @submit.prevent="onSave" @reset.prevent="resetForm">
            <b-row >
              <b-col cols="8">
                
                  <b-form-group label="Message" label-for="Message" label-cols-md="2">
                            <validation-provider
                #default="{ errors }"
                name="Message"
                vid="Message"
                rules="required"
              >
                            <b-form-textarea v-model="message" :state="errors.length > 0 ? false:null" placeholder="Please enter a message" />
                            <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
                        </b-form-group>
              </b-col>
            </b-row>
            <b-row>
            
              <b-col cols="10">
                <b-form-group label="" label-for="" label-cols-md="2">
                <b-button type="submit" :block="$store.getters['app/currentBreakPoint'] === 'xs'" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
                    Submit
                </b-button></b-form-group>
              </b-col>
            </b-row>
        </b-form>
    </validation-observer>
    </b-modal>
        
    </b-card>


</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BAlert,
  BTable,
  BImg,
  BAvatar,
  BCardText,
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BButton,
  BOverlay,
  BFormDatepicker
} from "bootstrap-vue";
import axios from "axios";
import router from "@/router";
import { serverUri } from "@/config";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BCardBody,
    BCardHeader,
    BAlert,
    BTable,
    BAvatar,
    BImg,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BFormDatepicker
  },
  data() {
    return {
      driverDetails : getUserData(),
      showUpdate : false,
      message: "",
      isClicked: true,
      toDate: null,
      fromDate: null,
      paymentDate: null,
      items: [],
      nextTodoId: 2,
      totalDataTable: [
        {
          driver_id: null,
          driver_name: null,
          fixed_rate_trips: 0,
          fixed_rate_earning: 0,
          miles_driven: 0,
          spots_cnt: 0,
          spots_value: 0,
          price_per_mile: 0,
          total: 0,
        },
      ],
      assignee : [],
      detailDataTable: [],
      totalFields: [
       
        {
          key: "fixed_rate_trips",
          label: "# OF TRIPS",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "fixed_rate_earning",
          label: "METRO $",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "miles_driven",
          label: "MILES DRIVEN",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "price_per_mile",
          label: "$/MILE",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "spots_cnt",
          label: "# OF SPOTS",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "spots_value",
          label: "SPOTS $",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "total",
          label: "TOTAL",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      detailFields: [
        {
          key: "date",
          label: "DATE",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "origin",
          label: "ORIGIN",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "destination",
          label: "DESTINATION",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "miles",
          label: "MILES",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "pay_rate",
          label: "PAY RATE",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "payroll_value",
          label: "PAYROLL VALUE",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      detailDataHourlyTable: [],
      totalDataHourlyTable: [
        {
          driver_id: null,
          driver_name: null,
          pay_type: 'Hourly',
          total_clock_in_out: 0,
          per_hour_rate: getUserData().price_per_mile,
          total_hours: 0,
          total_earning: 0,
        },
      ],
      totalHourlyFields: [
        {
          key: "pay_type",
          label: "PAY TYPE",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "total_clock_in_out",
          label: "# OF Clock in/out",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "per_hour_rate",
          label: "Per Hour $",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "total_hours",
          label: "Total Hours",
          thClass: "text-center",
          tdClass: "text-center",
        },
        
        {
          key: "total_earning",
          label: "TOTAL EARNING $",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      detailHourlyFields: [
        {
          key: "date",
          label: "DATE",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "clock_in",
          label: "CLOCK IN",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "clock_out",
          label: "CLOCK OUT",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "hours",
          label: "HOURS",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "per_hour",
          label: "PER HOUR PAYMENT $",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "total_hour",
          label: "TOTAL HOUR PAYMENT $",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      detailDataDayTable: [],
      totalDataDayTable: [
        {
          driver_id: null,
          driver_name: null,
          pay_type: 'Per Day',
          total_clock_in_out: 0,
          per_day_rate: getUserData().price_per_mile,
          total_days: 0,
          total_earning: 0,
        },
      ],
      totalDayFields: [
        {
          key: "pay_type",
          label: "PAY TYPE",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "per_day_rate",
          label: "Per Day $",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "total_days",
          label: "Total Days",
          thClass: "text-center",
          tdClass: "text-center",
        },
        
        {
          key: "total_earning",
          label: "TOTAL EARNING $",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      detailDayFields: [
        {
          key: "date",
          label: "DATE",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "clock_in",
          label: "CLOCK IN",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "clock_out",
          label: "CLOCK OUT",
          sortable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    };
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  created() {
    // this.toDate = router.currentRoute.params.toDate;
    // this.fromDate = router.currentRoute.params.fromDate;
    // this.paymentDate = router.currentRoute.params.paymentDate;

    const company = getUserData().company_id;

        axios
            .get(`${serverUri}/api/payroll/earningIndex/${company}`)
            .then((res) => {
                this.toDate = res.data.toDate;
                this.fromDate = res.data.fromDate;

                this.fromDate.split('-').slice(1).join('-');
                this.toDate.split('-').slice(1).join('-');
                
                this.paymentDate = res.data.paymentDate
                if (res.data.res.length)
                    this.rows = res.data.res;
                    this.onLoadTable();

            });

    if (this.toDate && this.fromDate) {
      this.onLoadTable();

      window.addEventListener("resize", this.initTrHeight);
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  mounted() {
    this.getCompanyUsers();
  },
  methods: {
    search()
    {
      this.detailDataTable = [];
      this.onLoadTable();
    },
    getCompanyUsers()
    {
      this.$http
        .get(`${serverUri}/users/allpayrolluser/${getUserData().company_id}`)
        .then((response) => {
          this.data = response.data;
            response.data.map((item) => {
              this.assignee.push({
                fullName: item.name,
                key: item.id,
              });
          });
        });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          alert("password reset!");
        }
      });
    },

    onSave() {
        if(this.message == '')
        {
          console.log(getUserData());
            this.showToast(
              "danger",
              "Required",
              "The dispute report message has been required."
            );
        } else {

          var d = new Date();
	        const DueDate = moment(d.setDate(d.getDate() + 1)).format("YYYY-MM-DD");
          const Tags = [{title:'Payroll',label:'Payroll'}];
          const Assignee = this.assignee;

          axios
                .post(`${serverUri}/todo/add/${getUserData().id}`, {
                  title: getUserData().driver_name.toUpperCase()+" - Dispute Earnings",
                  status : 'pending',
                  dueDate : DueDate,
                  assignee: Assignee,
                  tags: Tags,
                  description: this.message,
                  company: getUserData().company_id,

                })
                .then((res) => {
                if (res.data[0]) 
                {
                   this. submitDisputeData();
                    this.showToast(
                    "success",
                    "Success",
                    "The dispute report has been submitted."
                    );

                  //  this.onLoadTable();
                } else {
                    this.showToast(
                    "danger",
                    "failure",
                    "The dispute report has not been submitted."
                    );
                }

         
                this.$refs["modal-tall"].hide();
                this.message = "";
                });
            }
    },

    submitDisputeData()
    {
      axios
                .post(`${serverUri}/driver/dispute_report_submit`, {
                driver_id: getUserData().id,
                message: this.message,
                })
                .then((res) => {
                if (res.data.status) 
                {
                    
                } else {
                  
                }
              });
    },
    onBack() {
      this.$router.push({
        name: "apps-payroll-earning",
      });
    },
    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },
    repeateAgain() {
      this.items.push({
        id: this.items[this.items.length - 1].id + 1,
      });
    },
    removeItem(index, id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.items.splice(index, 1);

          axios
            .delete(`${serverUri}/api/payroll/deletefr/${id}`)
            .then((res) => {
              this.showToast(
                "success",
                "Success",
                "The fixed rate has been deleted."
              );
            });

          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    onUpdateFR() {
      axios
        .post(`${serverUri}/api/payroll/updatefr`, {
          driver_id: this.totalDataTable[0].driver_id,
          driver_name: this.totalDataTable[0].driver_name,
          price_per_mile: this.totalDataTable[0].price_per_mile,
        })
        .then((res) => {
          if (res.data.affectedRows) {
            this.showToast(
              "success",
              "Success",
              "The price per mile has been updated."
            );

            this.onLoadTable();
          } else {
            this.showToast("danger", "failure", "Could not find the driver.");
          }
        });
    },
    onUpdateSetting() {
      axios
        .post(
          `${serverUri}/api/payroll/updatesetting/${router.currentRoute.params.company}`,
          this.items
        )
        .then((res) => {
          this.showToast(
            "success",
            "Success",
            "The fixed rate has been updated."
          );

          this.onLoadTable();
        });
    },
    onLoadTable() 
    {
      if(getUserData().pay_type == 1)
      {
          this.showUpdate = true;
          this.totalDataTable[0].total = 0;
          this.totalDataTable[0].fixed_rate_earning = 0;
          this.totalDataTable[0].spots_value = 0;
          axios
            .post(`${serverUri}/api/payroll/getpayroll/${getUserData().driver_id}`, {
              company: getUserData().company_id,
              startDate: this.fromDate,
              endDate: this.toDate,
            })
            .then((res) => {
              // this.fromDate = res.data.start;
              // this.toDate = res.data.end;
              // calculate total data
              res.data.tripData.map((item1) => {
                if (
                  !this.totalDataTable.driver_id ||
                  !this.totalDataTable.driver_name
                ) {
                  this.totalDataTable[0].driver_id = item1.driver_id;
                  this.totalDataTable[0].driver_name = item1.driver_name;
                }

                const filtering = res.data.rateSetting.filter(
                  (item2) =>
                    item1.miles_qty >= item2.from_miles &&
                    item1.miles_qty <= item2.to_miles
                );
                if (filtering.length) {
                  this.totalDataTable[0].fixed_rate_earning +=
                    filtering[0].fixed_rate;
                } else {
                  this.totalDataTable[0].miles_driven += item1.miles_qty;
                  this.totalDataTable[0].price_per_mile = item1.price_per_mile;
                }

                this.detailDataTable.push({
                  date: new Date(item1.date).toLocaleDateString("en-US", {
                    timeZone: "JST",
                  }),
                  origin: item1.leg_org,
                  destination: item1.leg_dest,
                  miles: item1.miles_qty,
                  pay_rate: filtering.length
                    ? `$${filtering[0].fixed_rate.toFixed(2)}`
                    : `$${item1.price_per_mile.toFixed(2)}`,
                  payroll_value: filtering.length
                    ? `$${filtering[0].fixed_rate.toFixed(2)}`
                    : `$${(item1.price_per_mile * item1.miles_qty).toFixed(2)}`,
                });
              });

              res.data.spotData.map((item1) => {
                const filtering = res.data.spotSetting.filter(
                  (item2) =>
                    item1.miles >= item2.from_miles && item1.miles <= item2.to_miles
                );
                if (filtering.length) {
                  this.totalDataTable[0].spots_value += filtering[0].spot_pay;
                } else {
                  this.totalDataTable[0].miles_driven += item1.miles;
                }

                this.detailDataTable.push({
                  date: new Date(item1.date).toLocaleDateString("en-US", {
                    timeZone: "JST",
                  }),
                  origin: item1.spot_name,
                  destination: item1.site_name,
                  miles: item1.miles,
                  pay_rate: filtering.length
                    ? `$${filtering[0].spot_pay.toFixed(2)}`
                    : `$${item1.price_per_mile.toFixed(2)}`,
                  payroll_value: filtering.length
                    ? `$${filtering[0].spot_pay.toFixed(2)}`
                    : `$${(item1.price_per_mile * item1.miles).toFixed(2)}`,
                });
              });

              this.totalDataTable[0].spots_cnt = res.data.spotData.length;
              this.totalDataTable[0].fixed_rate_trips =
                res.data.tripData.length + res.data.spotData.length;
                
              if(this.totalDataTable[0])
              {
                this.totalDataTable[0].total =
                  "$" +
                  (
                    parseFloat(this.totalDataTable[0].fixed_rate_earning) +
                    parseFloat(this.totalDataTable[0].spots_value) +
                    parseFloat(this.totalDataTable[0].miles_driven) *
                    parseFloat(this.totalDataTable[0].price_per_mile)
                  ).toFixed(2);
              }

              if(this.totalDataTable[0])
              {
                this.totalDataTable[0].fixed_rate_earning =
                  "$" + this.totalDataTable[0].fixed_rate_earning.toFixed(2);
                this.totalDataTable[0].spots_value =
                  "$" + this.totalDataTable[0].spots_value.toFixed(2);
              }

              this.items = res.data.rateSetting;

              this.showUpdate = false;

            });

        } else if(getUserData().pay_type == 2) {

          this.showUpdate = true;
          this.totalDataHourlyTable[0].total_hours = 0;
          this.totalDataHourlyTable[0].total_clock_in_out = 0;
          this.totalDataHourlyTable[0].total_earning = 0;
          axios
            .post(`${serverUri}/api/payroll/gethourlypayroll`, {
              company: getUserData().company_id,
              driver: getUserData().id,
              startDate: this.fromDate,
              endDate: this.toDate,
            })
            .then((res) => {
              this.detailDataHourlyTable = [];
              if(res.data.status)
              {
                  res.data.data.map((item1) => {
                    const hours = Math.floor(item1.total_time / 3600);
                    const minutes = Math.floor((item1.total_time % 3600) / 60);
                    const perHour = getUserData().price_per_mile;
                    const totalHourPayment = (hours * perHour).toFixed(2);

                    this.totalDataHourlyTable[0].total_earning += parseFloat(totalHourPayment);
                    this.totalDataHourlyTable[0].total_hours += parseInt(hours);


                    this.detailDataHourlyTable.push({
                        date: moment(item1.date).format("MM/DD/YYYY"),
                        clock_in: moment(item1.clock_in).format("HH:mm:ss"),
                        clock_out: moment(item1.clock_out).format("HH:mm:ss"),
                        hours:hours,
                        per_hour:perHour,
                        total_hour:totalHourPayment,
                        
                    
                    });
                });

                this.totalDataHourlyTable[0].total_clock_in_out = res.data.data.length;

              }

              this.showUpdate = false;

            });

        } else {

          this.showUpdate = true;
          const totalDays = [];
          this.totalDataDayTable[0].total_days = 0;
          this.totalDataDayTable[0].total_earning = 0;
          axios
            .post(`${serverUri}/api/payroll/gethourlypayroll`, {
              company: getUserData().company_id,
              driver: getUserData().id,
              startDate: this.fromDate,
              endDate: this.toDate,
            })
            .then((res) => {
              this.detailDataDayTable = [];
              if(res.data.status)
              {
                  res.data.data.map((item1) => {

                    this.totalDataDayTable[0].total_earning += 0;
                    this.totalDataDayTable[0].total_days += 0;

                    totalDays.push(moment(item1.date).format("MM/DD/YYYY"));
                    this.detailDataDayTable.push({
                        date: moment(item1.date).format("MM/DD/YYYY"),
                        clock_in: moment(item1.clock_in).format("HH:mm:ss"),
                        clock_out: moment(item1.clock_out).format("HH:mm:ss"),
                    });
                });

                const countTotalDays = [ ...new Set(totalDays) ];

                this.totalDataDayTable[0].total_days = countTotalDays.length;
                this.totalDataDayTable[0].total_earning = (countTotalDays.length * getUserData().price_per_mile).toFixed(2) ;


              }
              this.showUpdate = false;

        });
    };
  }
}
}

</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
